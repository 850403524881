import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid } from "@mui/material";

import { FUND_ADJUSTMENT_VALUE_TYPE_ENUM } from "types/adjustment";
import { shortFormat } from "utils/date-format";
import Layout from "components/layout/layout";

import { TransactionDetailsCard } from "components/transaction-details/transaction-details";
import { TransactionDetailsItem } from "components/transaction-details/transaction-details-item";
import { BackToPreviousButton } from "components/shared/back-to-previous-button";
import { useGetAdjustmentQuery } from "hooks/use-services/fund-adjustment.service";

import { ReactComponent as InstantMoney } from "./../../assets/Icons/instant-money.svg";

export const AdjustmentDetailsPage = () => {
  const { id } = useParams();

  const { data: adjustmentData, isLoading } = useGetAdjustmentQuery(
    parseInt(id!)
  );
  return (
    <Layout>
      {() =>
        isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ my: 4, width: "100%" }}>
              <>
                <Grid sx={{ m: 10 }} container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <BackToPreviousButton />
                  </Grid>
                  {adjustmentData && (
                    <Grid item xs={12} sm={9}>
                      <TransactionDetailsCard
                        amount={
                          adjustmentData.valueType ===
                          FUND_ADJUSTMENT_VALUE_TYPE_ENUM.POSITIVE
                            ? adjustmentData.amount
                            : adjustmentData.amount * -1
                        }
                        type={`ADJUSTMENT`}
                        title={`Neta Foundation`}
                        avatar={<InstantMoney />}
                        status={"PROCESSED"}
                      >
                        <TransactionDetailsItem
                          label="Type"
                          value={`ADJUSTMENT`}
                        />
                        <TransactionDetailsItem
                          label="Description"
                          value={adjustmentData.description}
                        />
                        <TransactionDetailsItem
                          label="DATE"
                          value={shortFormat(adjustmentData?.executionDate)!}
                        />
                        <TransactionDetailsItem
                          label="INITIATED BY"
                          value={`Neta Admin`}
                        />
                        <TransactionDetailsItem
                          label="Fund"
                          value={adjustmentData?.fundAccount?.fundAccountName}
                        />
                      </TransactionDetailsCard>
                    </Grid>
                  )}
                </Grid>
              </>
            </Box>
          </>
        )
      }
    </Layout>
  );
};
