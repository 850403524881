import React from "react";
import { Typography } from "@mui/material";

export const Copyright: React.FC = () => {
  return (
    <>
      <Typography variant="smallCopy" gutterBottom>
        © 2024-2025 Neta Foundation Inc. All rights reserved.
      </Typography>
    </>
  );
};
export default Copyright;
