import React, { useContext, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  listItemClasses,
} from "@mui/material";
import { warm, neutral, errorAlert, primary } from "style/color";
import { addWeeksToDate, numberRange, shortFormat } from "utils/date-format";
import { moneyFormat } from "utils/money-format";
import { useGetMovementsQuery } from "hooks/use-services/movements.service";
import {
  FUND_MOVEMENT_STATUS_ENUM,
  FUND_MOVEMENT_TYPE_ENUM,
  FundMovementHistory,
} from "types/fund-movements-history";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { ReactComponent as EyeIcon } from "./../../assets/Icons/eye.svg";
import { ReactComponent as EyeClosedIcon } from "./../../assets/Icons/eye-closed.svg";
import { useNavigate } from "react-router-dom";
import {
  ContributionTransactionDetailPageUrl,
  GrantVestmentRequestDetailPageUrl,
  MyCashPageUrl,
  MyDividendDistributionDetailPage,
  MyFeeDetailPage,
  MyGrantsTransactionDetailPagePath,
  MyInvestmentsRequestTransactionDetailPage,
  CashTransferDetailPageUrl,
  FundAdjustmentDetailsPage,
} from "constants/pages";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { generateUrl } from "utils/generateUrl";

interface MovementsCardProps {
  title: string;
  limit: number;
  dashboard?: boolean;
}
export const MovementsCard: React.FC<MovementsCardProps> = ({
  title,
  limit,
  dashboard,
}) => {
  const navigate = useNavigate();
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const [year, setYear] = React.useState<number>(new Date().getFullYear());
  const typesFilter = [
    { title: "Contributions", status: FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION },
    { title: "Grants", status: FUND_MOVEMENT_TYPE_ENUM.GRANT },
    { title: "Investments", status: FUND_MOVEMENT_TYPE_ENUM.INVESTMENT },
    { title: "GrantVestments", status: FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT },
    { title: "Distribution", status: FUND_MOVEMENT_TYPE_ENUM.DISTRIBUTION },
    { title: "Fee", status: FUND_MOVEMENT_TYPE_ENUM.FEE },
    { title: "Adjustment", status: FUND_MOVEMENT_TYPE_ENUM.ADJUSTMENT },
    { title: "Transfer", status: FUND_MOVEMENT_TYPE_ENUM.TRANSFER },
  ];
  const [types, setTypes] = React.useState<string[]>([
    FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION,
    FUND_MOVEMENT_TYPE_ENUM.GRANT,
    FUND_MOVEMENT_TYPE_ENUM.INVESTMENT,
    FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT,
    FUND_MOVEMENT_TYPE_ENUM.DISTRIBUTION,
    FUND_MOVEMENT_TYPE_ENUM.FEE,
    FUND_MOVEMENT_TYPE_ENUM.ADJUSTMENT,
    FUND_MOVEMENT_TYPE_ENUM.TRANSFER,
  ]);
  const handleYearChange = (year: number) => {
    setYear(year);
  };

  const [endDate] = React.useState<Date>(addWeeksToDate(1));
  const {
    data: paginatedData,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetMovementsQuery({
    limit: limit,
    fundId: selectedAccount?.fundAccountId || 0,
    filter: {
      types: types,
    },
    dateTimeRange: {
      startsAt: new Date(`${year}-01-01`),
      endsAt:
        year !== new Date().getFullYear()
          ? new Date(`${year}-12-31T23:59:59.000Z`)
          : endDate,
    },
  });

  const contains = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    return types.includes(type);
  };
  const handleTypeChange = (type: FUND_MOVEMENT_TYPE_ENUM) => {
    setTypes((prev) => {
      const index = prev.findIndex((item) => item === type);
      let filterTypes = [];

      if (index !== -1) {
        filterTypes = [...prev];
        filterTypes.splice(index, 1);
      } else {
        filterTypes = [...prev, type];
      }
      return filterTypes;
    });
  };
  const GoToDetailsPage = (movement: FundMovementHistory) => {
    let url = "";
    switch (movement.type) {
      case FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION:
        url = ContributionTransactionDetailPageUrl;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.INVESTMENT:
        url = MyInvestmentsRequestTransactionDetailPage;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT:
        url = GrantVestmentRequestDetailPageUrl;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.GRANT:
        url = MyGrantsTransactionDetailPagePath;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.FEE:
        url = MyFeeDetailPage;
        break;
      case FUND_MOVEMENT_TYPE_ENUM.DISTRIBUTION:
        url = MyDividendDistributionDetailPage;
        break;

      case FUND_MOVEMENT_TYPE_ENUM.ADJUSTMENT:
        url = FundAdjustmentDetailsPage;
        break;

      case FUND_MOVEMENT_TYPE_ENUM.TRANSFER: {
        const cashUrl = generateUrl(CashTransferDetailPageUrl, {
          action: movement.title.includes("To") ? "send" : "receive",
          type: "CASH",
          id: movement.id,
        });

        url = cashUrl;
        break;
      }
    }
    url = url.replace(":id", movement.id.toString());
    navigate(url);
  };

  const movements = useMemo<FundMovementHistory[]>(
    () =>
      paginatedData ? paginatedData?.pages?.flatMap((item) => item.data) : [],
    [paginatedData]
  );
  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });
  const getStatus = (
    status: FUND_MOVEMENT_STATUS_ENUM,
    type: FUND_MOVEMENT_TYPE_ENUM
  ) => {
    switch (status) {
      case FUND_MOVEMENT_STATUS_ENUM.REJECTED:
        return "DECLINED";
      case FUND_MOVEMENT_STATUS_ENUM.ACCEPTED:
        return "APPROVED";
      case FUND_MOVEMENT_STATUS_ENUM.PENDING:
        return type === FUND_MOVEMENT_TYPE_ENUM.GRANT
          ? "PENDING"
          : "IN PROGRESS";
      case FUND_MOVEMENT_STATUS_ENUM.APPROVED:
        return type === FUND_MOVEMENT_TYPE_ENUM.GRANT
          ? "IN PROGRESS"
          : type === FUND_MOVEMENT_TYPE_ENUM.INVESTMENT
          ? "PROCESSED"
          : "APPROVED";
      default:
        return status;
    }
  };
  return (
    <Card sx={{ boxShadow: "none", p: 4 }}>
      <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ color: `neutral.main`, mr: 2 }}
              variant="subtitle3"
            >
              {title}
            </Typography>
            {dashboard && (
              <Button
                onClick={() => navigate(MyCashPageUrl)}
                className="link"
                variant="text"
                color="primary"
                size="large"
              >
                See all
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6} textAlign={"end"}>
            <Select
              id="title-select"
              size="small"
              name="title"
              value={year}
              onChange={(event) => handleYearChange(+event.target.value)}
            >
              {numberRange(new Date().getFullYear(), 2023, -1).map((year) => (
                <MenuItem key={year} value={year}>
                  {year === new Date().getFullYear() ? "Current year" : year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid sx={{ my: 1 }} container spacing={2}>
          {typesFilter.map((item, index) => {
            return (
              <Grid key={index} item>
                {contains(item.status) ? (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeIcon />}
                    variant={"contained"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                ) : (
                  <Button
                    key={index}
                    onClick={() => handleTypeChange(item.status)}
                    startIcon={<EyeClosedIcon />}
                    variant={"outlined"}
                    color="primary"
                    size="large"
                  >
                    {item.title}
                  </Button>
                )}
              </Grid>
            );
          })}
        </Grid>
        {movements.length !== 0 ? (
          <>
            <List
              className="successor"
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                mt: 2,
                [` & .${listItemClasses.root}:hover`]: {
                  backgroundColor: warm[200],
                  cursor: "pointer",
                },
              }}
            >
              {movements.map((movement: any, index: number) => {
                const { status } = movement;
                return (
                  <>
                    <ListItem
                      key={index}
                      onClick={() => GoToDetailsPage(movement)}
                    >
                      <Grid alignItems="center" container>
                        <Grid item xs={12} sm={3} textAlign={"start"}>
                          <ListItemText
                            sx={{
                              display: "inline-grid",
                              width: "auto",
                              flex: "none",
                            }}
                            primary={
                              <Typography
                                sx={{ color: `neutral.main` }}
                                variant="bodyCopyBold"
                                gutterBottom
                              >
                                {movement.title}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{ color: neutral[600] }}
                                variant="smallCopyBold"
                              >{`By ${movement.user}`}</Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{ color: neutral[600] }}
                          >
                            {shortFormat(movement.date)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} textAlign={"center"}>
                          <Typography
                            variant="bodyCopyRegular"
                            sx={{
                              color: neutral[900],
                              textTransform: "capitalize",
                            }}
                          >
                            {movement.type.toLowerCase()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"center"}>
                          <Button
                            sx={{
                              borderRadius: "4px",
                              p: 1,
                              backgroundColor: warm[200],
                              color: ["IN PROGRESS", "PENDING"].includes(
                                getStatus(status, movement.type)
                              )
                                ? neutral[500]
                                : status ===
                                    FUND_MOVEMENT_STATUS_ENUM.REJECTED ||
                                  status === FUND_MOVEMENT_STATUS_ENUM.CANCELED
                                ? errorAlert[500]
                                : primary[500],
                              paddingX: 2,
                              paddingY: 1.5,
                              fontFamily: "Quattrocento Sans",
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "13.3px",
                            }}
                          >
                            {getStatus(status, movement.type)}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={2} textAlign={"end"}>
                          <Typography
                            sx={{
                              px: 1,
                              py: 0.5,
                              fontStyle:
                                status === FUND_MOVEMENT_STATUS_ENUM.ACCEPTED &&
                                movement.type ===
                                  FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION
                                  ? "italic"
                                  : "",
                              color: !["IN PROGRESS", "PENDING"].includes(
                                getStatus(status, movement.type)
                              )
                                ? neutral[900]
                                : neutral[500],
                              backgroundColor:
                                movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.CONTRIBUTION
                                  ? primary[50]
                                  : "transparent",
                              textDecoration:
                                status === FUND_MOVEMENT_STATUS_ENUM.REJECTED ||
                                status === FUND_MOVEMENT_STATUS_ENUM.CANCELED
                                  ? "line-through"
                                  : "none",
                              borderRadius: 1,
                            }}
                            variant="bodyCopyBold"
                            gutterBottom
                          >
                            <>
                              {movement.type === FUND_MOVEMENT_TYPE_ENUM.FEE ||
                              movement.type === FUND_MOVEMENT_TYPE_ENUM.GRANT ||
                              movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.GRANTVESTMENT ||
                              (movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.INVESTMENT &&
                                movement.transactionType === "BUY") ||
                              (movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.ADJUSTMENT &&
                                movement.transactionType === "NEGATIVE") ||
                              (movement.type ===
                                FUND_MOVEMENT_TYPE_ENUM.TRANSFER &&
                                movement.title.toString().includes("To"))
                                ? `-${moneyFormat(movement.amount)}`
                                : `${moneyFormat(movement.amount)}`}
                            </>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {index !== movements.length - 1 && (
                      <Divider key={index + 100} />
                    )}
                  </>
                );
              })}
            </List>
            {!dashboard && (
              <Box ref={lastElementRef}>
                {isFetchingNextPage && (
                  <Grid item xs={12} sm={10} textAlign={"center"} sx={{ m: 3 }}>
                    {" "}
                    <CircularProgress color="primary" />
                  </Grid>
                )}
              </Box>
            )}
          </>
        ) : (
          <Typography
            sx={{ mt: 4, color: neutral[600] }}
            variant="bodyCopyRegular"
          >
            You don't have any past transactions.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
