import { Account } from "./account";
import { User } from "./user";
export enum FUND_ADJUSTMENT_VALUE_TYPE_ENUM {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

export interface Adjustment {
  fundAdjustmentId: number;
  fundAccount: Account;
  valueType: FUND_ADJUSTMENT_VALUE_TYPE_ENUM;
  description: string;
  executionDate: Date;
  amount: number;
  user: User;
}
