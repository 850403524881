import React, { useContext, useEffect } from "react";
import Layout from "../../components/layout/layout";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  Typography,
  Link,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GrantVestmentsCard from "../../components/grant-vestments/grant-vestment-card";
import { createSearchParams, useNavigate } from "react-router-dom";
import { GrantVestmentsSearchPage } from "../../constants/pages";
import { GRANT_VESTMENT } from "../../constants/grant-vestments";
import { GrantVestment } from "types/grant-vestment";
import { useGetCategoriesQuery } from "hooks/use-services/category.service";
import { useGetGrantVestmentsQuery } from "hooks/use-services/grant-vestment.service";
import mixpanel from "mixpanel-browser";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
export default function GrantVestmentsHome() {
  const { userData } = useContext<AuthData>(AuthContext);
  const { data: categories, isSuccess } = useGetCategoriesQuery({ limit: 30 });
  const { data: grantVestments, isSuccess: isSuccessGrantVestment } =
    useGetGrantVestmentsQuery({
      filters: {
        sort: "Newest",
        show: "All",
      },
    });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [newestGrantVestments, setNewestGrantVestments] =
    React.useState<GrantVestment>(GRANT_VESTMENT);
  const searchByCategory = (category: number) => {
    const result = categories?.data.find(
      (item) => item.categoryId === category
    );

    try {
      if (mixpanel && typeof mixpanel.track === "function") {
        mixpanel.track("GrantVestmentSearchByCategory", {
          category: result?.categoryName || "",
          user: userData?.user
            ? `${userData.user.firstName} ${userData.user.lastName}`
            : "",
          email: userData?.user?.email || "",
        });
      }
    } catch (error) {
      console.error("Error tracking category search:", error);
    }

    navigate({
      pathname: GrantVestmentsSearchPage,
      search: `?${createSearchParams({ category: category.toString() })}`,
    });
  };
  const searchByKeyword = (event: any) => {
    if (event.key === "Enter") {
      try {
        if (mixpanel && typeof mixpanel.track === "function") {
          mixpanel.track("GrantVestmentSearchByKeyword", {
            keyword: event.target.value || "",
            user: userData?.user
              ? `${userData.user.firstName} ${userData.user.lastName}`
              : "",
            email: userData?.user?.email || "",
          });
        }
      } catch (error) {
        console.error("Error tracking keyword search:", error);
      }

      navigate({
        pathname: GrantVestmentsSearchPage,
        search: `?${createSearchParams({ keyword: event.target.value })}`,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isSuccessGrantVestment) {
      setNewestGrantVestments(grantVestments.data[0]);
    }
  });
  return (
    <Layout>
      {() => (
        <Box sx={{ m: 4 }}>
          <Typography sx={{ color: "neutral.main" }} variant="h3" gutterBottom>
            GrantVestmentsᵀᴹ
          </Typography>
          <Typography variant="bodyCopyRegular" paragraph>
            We want to help you deploy your charitable money toward causes you
            care about. Please{" "}
            <Link
              target="_blank"
              href={`mailto:contact@netafoundation.org`}
              underline="always"
              color={"inherit"}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              {" "}
              contact us
            </Link>{" "}
            if you want to suggest a GrantVestmentᵀᴹ or for a consultation about
            identifying new opportunities.
          </Typography>

          {newestGrantVestments ? (
            <>
              <Grid
                sx={{ my: 2 }}
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                {!open && (
                  <Grid sx={{ pl: 0 }} item xs={12} sm={8}>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      {isSuccess &&
                        categories.data.map((category) => {
                          return (
                            <Button
                              sx={{ pl: 0 }}
                              key={category.categoryId}
                              variant="header"
                              onClick={() =>
                                searchByCategory(category.categoryId)
                              }
                            >
                              {category.categoryName}
                            </Button>
                          );
                        })}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12} sm={open ? 12 : 4} sx={{ textAlign: "end" }}>
                  <FormControl variant="outlined" fullWidth>
                    <FilledInput
                      className="search"
                      onFocus={() => setOpen(true)}
                      onBlur={() => setOpen(false)}
                      onKeyDown={(event) => {
                        searchByKeyword(event);
                      }}
                      placeholder="Type keyword or name"
                      size="small"
                      id="outlined-adornment-weight"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                sx={{ my: 2 }}
                container
                justifyContent="start"
                columnSpacing={2}
                rowSpacing={2}
                columns={16}
                alignItems="center"
              >
                {grantVestments?.data.map((grantvestment, index) => {
                  return (
                    <Grid key={index} item xs={12} sm={8} md={5}>
                      <GrantVestmentsCard
                        grantvestment={grantvestment}
                        single={false}
                        allContent={false}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <Typography
              sx={{ color: "neutral.main", mt: 4, mb: 2 }}
              variant="subtitle2"
              gutterBottom
            >
              Exciting News! GrantVestmentᵀᴹ is coming soon!
            </Typography>
          )}

          {/*  <Typography sx={{ color: "neutral.main", mt: 4, mb: 2 }} className="title" variant='subtitle2' gutterBottom>
                        recommended for you
                    </Typography>
                    <Grid sx={{ my: 2 }} container justifyContent="space-between" columnSpacing={3} rowSpacing={2}
                        alignItems="center">
                        {
                            (grantVestmentsList as GrantVestment[]).map((grantvestment, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={6} md={4} >
                                        <GrantVestmentsCard grantvestment={grantvestment} single={false} allContent={false} />
                                    </Grid>

                                )
                            })
                        }

                    </Grid> */}
        </Box>
      )}
    </Layout>
  );
}
