import { useContext, useEffect } from "react";
import Layout from "../../components/layout/layout";
import { Box } from "@mui/material";

import { AuthContext } from "../../providers/userProvider";
import { useLocation } from "react-router-dom";
import { AuthData } from "types/auth";
import GrantVestmentsDetailsCard from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-card";
import GrantVestmentsDetailsTags from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tags";
import GrantVestmentsDetailsTitles from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-titles";
import GrantVestmentsDetailsTabs from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tabs";
import { useGetGrantVestmentQuery } from "hooks/use-services/grant-vestment.service";
import mixpanel from "mixpanel-browser";

export const Grantvestment = () => {
  const { state } = useLocation();
  const { userData } = useContext<AuthData>(AuthContext);
  const { data: grantvestment } = useGetGrantVestmentQuery({
    grantVestmentId: parseInt(state.id || "0"),
  });

  useEffect(() => {
    if (userData?.user && grantvestment?.name && mixpanel) {
      mixpanel.track("UserOpenedGrantVestmentPage", {
        grantvestment: grantvestment?.name,
        user: `${userData.user.firstName} ${userData.user.lastName}`,
        email: userData.user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantvestment?.name, userData?.user]);

  const track = (type: string, name: string, link: string) => {
    try {
      // Only track if user is logged in
      if (
        !userData?.user ||
        !mixpanel ||
        typeof mixpanel.track !== "function"
      ) {
        return;
      }

      const title =
        type === "dataRoom"
          ? "UserOpenedGrantVestmentDataRoomLink"
          : "UserOpenedGrantVestmentUpdateLink";

      mixpanel.track(title, {
        grantvestment: grantvestment?.name || "",
        title: name,
        url: link,
        user: `${userData.user.firstName} ${userData.user.lastName}`,
        email: userData.user.email,
      });
    } catch (error) {
      console.error(`Error tracking ${type} link click:`, error);
    }
  };

  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 4 }}>
          {grantvestment && (
            <>
              {/* details-titles */}
              <GrantVestmentsDetailsTitles grantvestment={grantvestment!} />
              {/* details-card */}
              <GrantVestmentsDetailsCard
                handleOpen={handleOpen}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
              />
              {/* details-tags */}
              <GrantVestmentsDetailsTags grantvestment={grantvestment!} />
              {/* details-tabs */}
              <GrantVestmentsDetailsTabs
                user={userData?.sub!}
                track={track}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
                handleOpen={handleOpen}
              />
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};
