import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateBefore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import Layout from "components/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetGrantVestmentQuery,
  useMakeGrantVestmentRequestMutation,
} from "hooks/use-services/grant-vestment.service";
import { AuthContext } from "providers/userProvider";
import { AuthData } from "types/auth";
import { useContext, useState } from "react";
import { getPercentage, moneyFormat, unitFormat } from "utils/money-format";
import { GrantVestment } from "types/grant-vestment";
import imagePlaceholader from "./../../assets/image-placeholader.png";
import { longFormat, numberOfDays, shortFormat } from "utils/date-format";
import { warm } from "style/color";
import { TransactionTypeSelector } from "components/shared/request-type-selector";
import { GRANT_REQUEST_TRANSACTION_TYPE_ENUM } from "types/grant-request";

const GrantVestmentRequestPage = () => {
  const navigate = useNavigate();
  const { grantVestmentId } = useParams();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const { data: grantvestment } = useGetGrantVestmentQuery({
    grantVestmentId: parseInt(grantVestmentId || "0"),
  });
  const {
    mutate: makeGVRequest,
    isLoading: isMakingGVRequest,
    isSuccess: isMakingGVRequestDone,
  } = useMakeGrantVestmentRequestMutation(parseInt(grantVestmentId || "0"));

  const [transactionType, setTransactionType] = useState(
    GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
  );

  const [information, setInformation] = useState({
    requestedAmount: 0,
    ticker: "",
    units: 0,
    interestedIn: "CASH",
  });

  const [cashError, setCashError] = useState(false);
  const [investmentError, setInvestmentError] = useState(false);

  const handleTransactionTypeChange = (
    newTransactionType: any,
    newInformation: any
  ) => {
    setTransactionType(newTransactionType);
    setInformation(newInformation);
    if (newTransactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH) {
      setInvestmentError(false);
    } else {
      setCashError(false);
    }
  };

  const handleInformationChange = (newInformation: any) => {
    setInformation((prevInfo) => ({
      ...prevInfo,
      ...newInformation,
    }));
  };

  const onConfirmGVRequest = () => {
    if (transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH) {
      makeGVRequest({
        requestedAmount: information.requestedAmount,
        fundId: selectedAccount?.fundAccountId as number,
        transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH,
      });
    } else {
      makeGVRequest({
        requestedAmount: information.requestedAmount,
        fundId: selectedAccount?.fundAccountId as number,
        transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT,
        investmentInfo: {
          requestedAmount: information.requestedAmount,
          appliedAmount: information.requestedAmount,
          interestedIn: information.interestedIn,
          units: information.units,
          ticker: information.ticker,
        },
      });
    }
  };

  return (
    <Layout>
      {() => (
        <Box sx={{ m: 5 }}>
          <Button
            onClick={() => navigate(-1)}
            className="footer"
            variant="text"
            sx={{
              color: "neutral.main",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            }}
            startIcon={<NavigateNextIcon />}
          >
            <Typography variant="bodyCopyBold">
              Back to GrantVestmentsᵀᴹ
            </Typography>
          </Button>
          <GrantVestmentRequestPageStepper
            activeStep={isMakingGVRequestDone ? 1 : 0}
          />
          {isMakingGVRequestDone ? (
            <Box sx={{ paddingTop: 10 }}>
              <ThankYou4YourGrantVestmentRequest
                information={information}
                grantvestment={grantvestment!}
                transactionType={transactionType}
              />
            </Box>
          ) : (
            <>
              <Box>
                {grantvestment && (
                  <GrantVestmentCard4Request grantvestment={grantvestment} />
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Paper
                    variant="outlined"
                    sx={{ paddingY: 6, paddingX: 4, border: "none" }}
                  >
                    <Stack spacing={3}>
                      <TransactionTypeSelector
                        transactionType={transactionType}
                        handleTransactionTypeChange={
                          handleTransactionTypeChange
                        }
                        information={information}
                        setInformation={handleInformationChange}
                        setInvestmentAmountError={setInvestmentError}
                        investmentAmountError={investmentError}
                        cashError={cashError}
                        setCashError={setCashError}
                        TransactionType="INVESTMENT"
                      />
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={2}>
                    <Paper
                      variant="outlined"
                      sx={{ paddingY: 5, paddingX: 4, border: "none" }}
                    >
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "neutral.main" }}
                          gutterBottom
                        >
                          Summary
                        </Typography>
                        <Typography
                          variant="bodyCopyRegular"
                          sx={{ color: "neutral.main" }}
                        >
                          {selectedAccount?.fundAccountName}
                        </Typography>
                      </Box>
                      {!!information.requestedAmount && (
                        <>
                          <Box marginTop={3}>
                            <Typography
                              variant="smallCopy"
                              sx={{ color: "neutral.main", mb: 1 }}
                              className="title"
                              paragraph
                            >
                              GrantVesting into
                            </Typography>
                            <Typography
                              variant="subtitle3"
                              sx={{ color: "neutral.main", mb: 0 }}
                              paragraph
                            >
                              {grantvestment?.name}
                            </Typography>
                            <Typography
                              variant="smallCopyBold"
                              color="neutral.main"
                            >
                              Funded at{" "}
                              {!grantvestment?.raisedAmount
                                ? 0
                                : +getPercentage(
                                    grantvestment?.raisedAmount!,
                                    grantvestment?.goalAmount!
                                  )}
                              %
                            </Typography>
                          </Box>
                          <Divider
                            sx={{
                              marginY: 2,
                              backgroundColor: warm[600],
                              borderWidth: "1px",
                            }}
                          />
                          <Box>
                            {transactionType ===
                              GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
                              <Box>
                                <Typography
                                  variant="smallCopy"
                                  sx={{ color: "neutral.main", mb: 1 }}
                                  className="title"
                                >
                                  Total units
                                </Typography>
                                <Typography
                                  sx={{ color: "primary.main" }}
                                  variant="subtitle1"
                                >
                                  {unitFormat(information.units)}
                                </Typography>
                              </Box>
                            )}
                            <Typography
                              variant="smallCopy"
                              sx={{ color: "neutral.main", mb: 1 }}
                              className="title"
                            >
                              Total amount
                            </Typography>
                            <Typography
                              sx={{ color: "primary.main" }}
                              variant="subtitle1"
                            >
                              ${moneyFormat(information.requestedAmount)}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Paper>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={
                        cashError ||
                        investmentError ||
                        information.requestedAmount === 0 ||
                        isMakingGVRequest
                      }
                      onClick={onConfirmGVRequest}
                    >
                      Confirm
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};

/**
 *
 * TODO:// put those components on differet files
 */

const GrantVestmentRequestPageStepper = ({ activeStep = 0 }) => {
  return (
    <Grid container sx={{ mb: 5, mt: 2 }}>
      <Grid item xs={12} sm={8} md={6}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>
              <Typography variant="smallCopy">STEP 1</Typography>
              <br />
              <Typography variant="subtitle3">Enter details</Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Typography variant="smallCopy">STEP 2</Typography>
              <br />
              <Typography variant="subtitle3">Request confirmation</Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

const ThankYou4YourGrantVestmentRequest = ({
  information,
  grantvestment,
  transactionType,
}: {
  information: {
    requestedAmount: number;
    interestedIn: string;
    units: number;
    ticker: string;
  };
  grantvestment: GrantVestment;
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
}) => {
  return (
    <Paper variant="outlined" sx={{ paddingY: 5, paddingX: 4, border: "none" }}>
      <Typography variant="h3" sx={{ color: "neutral.main" }} gutterBottom>
        Thank you!
      </Typography>
      <Typography sx={{ color: "neutral.main" }}>
        <Typography variant="bodyCopyBold">
          We received your request to GrantVest
        </Typography>
        <Typography variant="bodyCopyBold">
          {" "}
          {transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH ||
          (transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT &&
            information.interestedIn === "AMOUNT")
            ? `$${moneyFormat(information.requestedAmount)}`
            : `${unitFormat(information.requestedAmount)}units of ${
                information.ticker
              }`}
        </Typography>
        <Typography variant="bodyCopyBold"> into </Typography>
        <Typography variant="bodyCopyBold">{grantvestment.name}</Typography>
      </Typography>
      <br />
      <Typography variant="bodyCopyRegular">
        Our team will review this request as soon as possible.
        <br />
        Once approved and funded, this will show in your portfolio.
      </Typography>
    </Paper>
  );
};

//TODO: (make this reusable with proper name)
// this componet has copied form grant-vestment.tsx
const GrantVestmentCard4Request = ({
  grantvestment,
}: {
  grantvestment: GrantVestment;
}) => {
  const funded = +getPercentage(
    grantvestment?.raisedAmount!,
    grantvestment?.goalAmount!
  );
  const daysLeft =
    numberOfDays(grantvestment?.deadline) < 0
      ? 0
      : numberOfDays(grantvestment?.deadline);
  let image = imagePlaceholader;
  if (grantvestment && grantvestment.imageUrl) {
    image = grantvestment.imageUrl;
  }

  return (
    <Box sx={{ my: 4 }}>
      <Typography sx={{ color: "neutral.main" }} variant="h3" gutterBottom>
        {grantvestment?.name}
      </Typography>
      <Typography variant="bodyCopyRegular" paragraph>
        {grantvestment?.description}
      </Typography>
      <Typography variant="bodyCopyBold" sx={{ color: "neutral.main", mb: 5 }}>
        By {grantvestment?.projectBy}
      </Typography>

      <Card
        className="single"
        sx={{
          backgroundColor: "transparent",
          mt: 5,
          display: { xs: "block", sm: "block", md: "flex" },
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: { xs: "100%", md: "40%" }, height: "auto", flex: 2 }}
          image={image}
          alt="Live from space album cover"
        />
        <Box sx={{ ml: 2, width: "100%", flex: 3 }}>
          <CardContent>
            <Typography
              variant="smallCopy"
              className="title"
              sx={{ color: "neutral.lighter" }}
              paragraph
            >
              Raised
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "neutral.main" }}
              gutterBottom
            >
              ${moneyFormat(grantvestment?.raisedAmount!)}
              <Typography
                variant="subtitle3"
                sx={{ float: "right", display: { xs: "none", md: "block" } }}
              >
                ${moneyFormat(grantvestment?.goalAmount!)} goal
              </Typography>
            </Typography>
            <Box sx={{ width: "100%", my: 2 }}>
              <LinearProgress
                color="primary"
                variant="determinate"
                value={funded > 100 ? 100 : funded}
              />
            </Box>
            <Typography
              variant="smallCopy"
              color={"primary"}
              className="title"
              paragraph
            >
              {funded}% funded
            </Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
              <AccessTimeIcon />
              <Typography variant="bodyCopyBold" sx={{ color: "neutral.main" }}>
                {daysLeft} days to go - until{" "}
                {shortFormat(grantvestment?.deadline)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 1 }}>
              <PeopleOutlineIcon />
              <Typography variant="bodyCopyBold" sx={{ color: "neutral.main" }}>
                {grantvestment?.grantVestorCounter}
              </Typography>
            </Stack>
            {grantvestment?.allOrNothing && (
              <>
                <Typography
                  variant="smallCopyBold"
                  sx={{ color: "neutral.lighter", fontWeight: 700 }}
                >
                  All or nothing
                </Typography>
                <Typography
                  variant="smallCopy"
                  sx={{ color: "neutral.lighter" }}
                  paragraph
                >
                  This project only be funded if it reaches its goal by{" "}
                  {longFormat(grantvestment?.deadline)}
                </Typography>
              </>
            )}
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
};

export default GrantVestmentRequestPage;
