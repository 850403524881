import React from "react";
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { GrantVestmentRequest } from "types/grant-vestment";
interface CancelGrantVestmentRequestProps {
    grantVestmentRequest: GrantVestmentRequest,
    open: boolean;
    onClose: () => void;
    cancelGrantVestmentRequest: (id: number) => void;
}
export const CancelGrantVestmentRequest: React.FC<
    CancelGrantVestmentRequestProps
> = ({ open, onClose, grantVestmentRequest, cancelGrantVestmentRequest }) => {
    const submit = () => {
        cancelGrantVestmentRequest(grantVestmentRequest.grantVestmentRequestId);
        onClose()
    }
    return (
        <>
            {
                grantVestmentRequest &&
                <Dialog sx={{ m: 2 }} fullWidth={true}
                    maxWidth={'sm'} open={open} onClose={onClose}>

                    <DialogTitle sx={{ my: 2, pl: 4 }}>
                        <Typography sx={{ color: "neutral", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} variant='subtitle1'>
                            Are you sure to want to cancel this request?
                            <IconButton
                                onClick={onClose}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        backgroundColor: "transparent",
                                    }
                                }}
                            > <CloseIcon />
                            </IconButton>

                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ pl: 4 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ color: "neutral" }} variant='bodyCopyRegular'>
                                    If you cancel this request, the money will no longer be transferred to the grantVestment.



                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} textAlign={'center'}>
                                <Button sx={{ mr: 2 }} variant="text" color='primary' size='large' onClick={onClose} >
                                    Don’t cancel
                                </Button>
                                <Button variant="contained" color='primary' size='large' onClick={submit}    >
                                    Cancel request
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

        </>
    );
};
