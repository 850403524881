import React, { useContext, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import {
  GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
  CashInfo,
  InvestmentInfo,
} from "types/grant-request";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ErrorIcon } from "assets/Icons/error.svg";
import { ReactComponent as ExclamationIcon } from "assets/Icons/exclamation.svg";
import { NumericFormatCustom } from "components/shared/currency-input";
import { FundInvestment } from "types/fund-investment";
import { neutral, primary } from "style/color";
import useIntersectionObserver from "hooks/use-services/useIntersectionObserver";
import { useGetFundInvestmentRequestsQuery } from "hooks/use-services/investment-request.service";
import { AuthData } from "types/auth";
import { AuthContext } from "providers/userProvider";

interface TransactionTypeSelectorProps {
  transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM;
  handleTransactionTypeChange: (
    transactionType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM,
    information: any
  ) => void;
  information: any;
  setInformation: any;
  setInvestmentAmountError: any;
  investmentAmountError: boolean;
  cashError: boolean;
  setCashError: any;
  TransactionType: "INVESTMENT" | "GRANT";
}

export const TransactionTypeSelector: React.FC<
  TransactionTypeSelectorProps
> = ({
  transactionType,
  handleTransactionTypeChange,
  information,
  setInformation,
  setInvestmentAmountError,
  investmentAmountError,
  cashError,
  setCashError,
  TransactionType,
}) => {
  const { selectedAccount } = useContext<AuthData>(AuthContext);

  const [selectedFundInvestment, setSelectedFundInvestment] =
    useState<FundInvestment | null>(null);

  const {
    data,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useGetFundInvestmentRequestsQuery(selectedAccount?.fundAccountId || 0, {
    limit: 10,
  });

  const investmentsRequestsData = useMemo<FundInvestment[]>(
    () => (data ? data?.pages?.flatMap((item) => item.data) : []),
    [data]
  );

  const lastElementRef = useIntersectionObserver({
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
  });

  const handleTransactionType = (
    event: React.MouseEvent<HTMLElement>,
    newType: GRANT_REQUEST_TRANSACTION_TYPE_ENUM | null
  ) => {
    if (newType !== null) {
      setInvestmentAmountError(false);
      setSelectedFundInvestment(null);
      handleTransactionTypeChange(
        newType,
        newType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH
          ? {
              requestedAmount: 0,
            }
          : {
              requestedAmount: 0,
              interestedIn: "AMOUNT",
              units: 0,
              ticker: "",
            }
      );
    }
  };

  const selectFundInvestment = (ticker: string) => {
    setInvestmentAmountError(false);
    setInformation({
      ticker: ticker,
      requestedAmount: 0,
      units: 0,
    });
    const fundInvestment = investmentsRequestsData.find(
      (item) => item.investment.ticker === ticker
    );
    setSelectedFundInvestment(fundInvestment || null);
  };

  const handleRequestedAmountChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const units = value / selectedFundInvestment?.investment?.marketPrice;
      setInformation({
        requestedAmount: value,
        units: units,
      });
      const availableBalance =
        selectedFundInvestment?.investment?.marketPrice *
        selectedFundInvestment?.totalApprovedUnits;
      setInvestmentAmountError(value > availableBalance);
    }
  };

  const handleRequestedUnitsChange = (value: number) => {
    if (selectedFundInvestment?.investment?.marketPrice) {
      const amount = value * selectedFundInvestment?.investment?.marketPrice;
      setInformation({
        requestedAmount: amount,
        units: value,
      });
      const availableBalance =
        selectedFundInvestment?.investment?.marketPrice *
        selectedFundInvestment?.totalApprovedUnits;
      setInvestmentAmountError(amount > availableBalance);
    }
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle3" color={neutral[900]}>
          Use
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ToggleButtonGroup
          fullWidth
          size="medium"
          value={transactionType}
          onChange={handleTransactionType}
          exclusive
          color="primary"
        >
          <ToggleButton
            fullWidth
            className="add-money-toggle"
            sx={{ paddingY: 2 }}
            value={GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH}
            aria-label="left aligned"
          >
            Cash
          </ToggleButton>
          <ToggleButton
            fullWidth
            className="add-money-toggle"
            value={GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT}
            sx={{ paddingY: 2 }}
            aria-label="centered"
          >
            Investment
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.CASH && (
        <Grid sx={{ mt: 1 }} item xs={12} sm={11}>
          <FormControl fullWidth>
            <InputLabel shrink={false}>Enter amount </InputLabel>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={1} textAlign={"center"}>
                <Typography
                  sx={{ color: "neutral.main" }}
                  variant="subtitle3"
                  gutterBottom
                >
                  $
                </Typography>
              </Grid>
              <Grid item xs={11} sm={5}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  name="requestedAmount"
                  value={(information as CashInfo).requestedAmount}
                  onChange={(event) => {
                    setInformation({
                      ...information,
                      requestedAmount: event.target.value,
                    });

                    if (
                      (information as CashInfo).requestedAmount >
                      (selectedAccount?.availableBalance || 0)
                    ) {
                      console.log(true);
                      setCashError(true);
                    }
                  }}
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{ color: neutral[500], mb: 0 }}
                  className="title"
                  variant="smallCopy"
                  paragraph
                >
                  Available balance
                </Typography>
                <Typography
                  variant="bodyCopyBold"
                  sx={{ color: "neutral.main" }}
                >
                  ${moneyFormat(selectedAccount?.availableBalance || 0)}
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={11}>
                {(information as CashInfo).requestedAmount >
                  (selectedAccount?.availableBalance || 0) && (
                  <FormHelperText sx={{ m: 0 }}>
                    {" "}
                    <ErrorIcon /> Not enough funds available. Try paying with
                    cash and liquid investments.{" "}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
      )}
      {transactionType === GRANT_REQUEST_TRANSACTION_TYPE_ENUM.INVESTMENT && (
        <>
          <Grid sx={{ mt: 2 }} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel shrink={false} id="title">
                Select asset
              </InputLabel>
              <Select
                required
                fullWidth
                id="fund-investments-select"
                size="small"
                name="ticker"
                value={(information as InvestmentInfo).ticker}
                onChange={(event) => selectFundInvestment(event.target.value)}
              >
                {isSuccess &&
                  investmentsRequestsData.length > 0 &&
                  investmentsRequestsData.map((request) => (
                    <MenuItem
                      key={request.fundInvestmentId}
                      value={request.investment.ticker}
                    >
                      {request.investment.name}
                    </MenuItem>
                  ))}
                <MenuItem
                  sx={{ p: 0 }}
                  ref={lastElementRef as any}
                  value="loading"
                >
                  {isFetchingNextPage && (
                    <Box>
                      <CircularProgress
                        size={10}
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                        }}
                        color="primary"
                      />
                    </Box>
                  )}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle3" color={neutral[900]}>
              Enter amount
            </Typography>
            <Box sx={{ display: "flex" }}>
              <ExclamationIcon />
              <Typography
                sx={{ ml: 1 }}
                variant="smallCopyBold"
                color={neutral[900]}
                paragraph
              >
                All unit quantities are estimations, and will be confirmed once
                executed
              </Typography>
            </Box>
          </Grid>
          {selectedFundInvestment && (
            <Grid item xs={12} marginLeft={2}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{
                  pt: 1,
                  pb: 2,
                  border: `1px solid ${neutral[400]}`,
                  borderRadius: "8px",
                }}
              >
                <Grid
                  sx={{ display: "grid", justifyContent: "start" }}
                  item
                  xs={12}
                  sm={4}
                >
                  <Typography variant="smallCopyBold" color={neutral[600]}>
                    {selectedFundInvestment.investment.ticker}
                  </Typography>
                  <Typography variant="subtitle3" color={neutral[900]}>
                    {selectedFundInvestment.investment.name}
                  </Typography>
                  <Typography variant="smallCopyBold" color={neutral[600]}>
                    Available $
                    {moneyFormat(
                      selectedFundInvestment.totalApprovedUnits *
                        selectedFundInvestment.investment.marketPrice
                    )}
                  </Typography>
                </Grid>
                {(information as InvestmentInfo).interestedIn === "UNITS" ? (
                  <>
                    <Grid item xs={11} sm={3}>
                      <TextField
                        required
                        helperText={
                          investmentAmountError && (
                            <>
                              <ErrorIcon /> Not enough units available
                            </>
                          )
                        }
                        error={investmentAmountError}
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder="Units"
                        value={
                          (information as InvestmentInfo).units
                            ? moneyFormat(
                                (information as InvestmentInfo).units,
                                4,
                                0
                              )
                            : ""
                        }
                        onChange={(e) =>
                          handleRequestedUnitsChange(parseFloat(e.target.value))
                        }
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} textAlign={"center"}>
                      <Typography
                        sx={{ color: "neutral.main" }}
                        variant="subtitle3"
                        gutterBottom
                      >
                        units
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton
                        onClick={() =>
                          setInformation({
                            ...information,
                            interestedIn: "AMOUNT",
                          })
                        }
                        sx={{
                          backgroundColor: primary[500],
                          color: "#fff",
                          "&:hover": {
                            backgroundColor: primary[800],
                          },
                        }}
                      >
                        <SyncAltIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10} sm={3} textAlign={"start"}>
                      <Typography
                        variant="subtitle3"
                        color={neutral[900]}
                        gutterBottom
                      >
                        $
                        {moneyFormat(
                          (information as InvestmentInfo).requestedAmount,
                          2
                        )}
                        *
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={1} textAlign={"center"}>
                      <Typography
                        sx={{ color: "neutral.main" }}
                        variant="subtitle3"
                        gutterBottom
                      >
                        $
                      </Typography>
                    </Grid>
                    <Grid item xs={11} sm={3}>
                      <TextField
                        required
                        error={investmentAmountError}
                        helperText={
                          investmentAmountError && (
                            <>
                              <ErrorIcon /> Not enough units available
                            </>
                          )
                        }
                        fullWidth
                        size="small"
                        variant="outlined"
                        placeholder={"Amount"}
                        value={
                          (information as InvestmentInfo).requestedAmount
                            ? moneyFormat(
                                (information as InvestmentInfo).requestedAmount,
                                2,
                                0
                              )
                            : ""
                        }
                        onChange={(e) =>
                          handleRequestedAmountChange(
                            parseFloat(e.target.value)
                          )
                        }
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                        }}
                      />
                    </Grid>

                    {transactionType !== "INVESTMENT" && (
                      <Grid item xs={2} sm={1}>
                        <IconButton
                          onClick={() =>
                            setInformation({
                              ...information,
                              interestedIn: "UNITS",
                            })
                          }
                          disabled={transactionType === "INVESTMENT"}
                          sx={{
                            backgroundColor: primary[500],
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: primary[800],
                            },
                          }}
                        >
                          <SyncAltIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs={10} sm={3} textAlign={"start"}>
                      <Typography
                        variant="subtitle3"
                        color={neutral[900]}
                        gutterBottom
                      >
                        {moneyFormat(
                          (information as InvestmentInfo).units,
                          4
                        ) || 0}{" "}
                        * units
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
