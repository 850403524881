import {
  GRANTVESTMENT_STATUS,
  GrantVestment,
  GrantVestmentRequest,
} from "types/grant-vestment";
import apiHttpClient from "libs/api-http-client";
import { PaginatedEntityList } from "types/pagination";
const getOrderBy = (value: string) => {
  switch (value) {
    case "Newest":
      return { createdAt: "DESC" };
    case "End date":
      return { deadline: "DESC" };
    case "Most funded":
      return { raisedAmount: "DESC" };
    case "Least funded":
      return { raisedAmount: "ASC" };
    default:
      return { createdAt: "DESC" };
  }
};

const prepareQuery = (filters: any) => {
  let query: any;
  if (filters?.keyword !== "") {
    query = { ...query, keyword: filters?.keyword };
  }

  if (filters?.category !== 0) {
    query = { ...query, category: filters?.category };
  }
  if (filters?.show !== "All") {
    query = { ...query, status: [filters?.show] };
  } else {
    query = {
      ...query,
      status: [GRANTVESTMENT_STATUS.CLOSED, GRANTVESTMENT_STATUS.RAISING],
    };
  }
  query = { ...query, sort: getOrderBy(filters?.sort) };
  return query;
};
export const getGrantVestmentAPI = (
  grantVestmentId: number
): Promise<GrantVestment> =>
  apiHttpClient.get(`/grant-vestments/${grantVestmentId}`);

export const makeGrantVestmentRequestAPI = (
  grantVestmentId: number,
  data: {
    requestedAmount: number;
    fundId: number;
  }
): Promise<unknown> =>
  apiHttpClient.post(`/grant-vestments/${grantVestmentId}/requests`, data);

export const getGrantVestmentRequestAPI = (
  grantVestmentRequestId: number
): Promise<GrantVestmentRequest> =>
  apiHttpClient.get(`/grant-vestment-requests/${grantVestmentRequestId}`);
export const cancelGrantVestmentRequestAPI = (
  grantVestmentRequestId: number
): Promise<GrantVestmentRequest> =>
  apiHttpClient.patch(
    `/grant-vestment-requests/${grantVestmentRequestId}/cancel`
  );
export interface getGrantVestmentsQuery {
  offset?: number;
  limit?: number;
  filters?: any;
}
export const getGrantVestmentsAPI = (
  query: getGrantVestmentsQuery
): Promise<PaginatedEntityList<GrantVestment>> => {
  const filters = prepareQuery(query.filters);
  return apiHttpClient.get(`/grant-vestments`, {
    params: {
      offset: query.offset,
      limit: query.limit,
      searchTerm: filters?.keyword,
      filter: {
        categoryId: filters?.category,
        status: filters?.status,
      },
      orderBy: filters.sort,
    },
  });
};
export interface GetGrantVestmentRequestsQuery {
  offset?: number;
  limit?: number;
  filter: {
    fundAccountId: number;
  };
}

export const getGrantVestmentRequestsByFundAPI = (
  grantVestmentId: number,
  query: GetGrantVestmentRequestsQuery
): Promise<PaginatedEntityList<GrantVestmentRequest>> => {
  return apiHttpClient.get(`/grant-vestments/${grantVestmentId}/requests`, {
    params: {
      offset: query.offset,
      limit: query.limit,
      filter: {
        fundAccountId: query.filter.fundAccountId,
      },
    },
  });
};
