import React, { useContext, useMemo } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Divider, Grid, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Layout from 'components/layout/layout';
import { FundSettingsPage, InviteUserPagePath } from 'constants/pages';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { neutral } from 'style/color';
import { numberOfDays, shortFormat } from 'utils/date-format';
import { RolesSettingsMenu } from './settings-menu';
import { useGetFundRolesQuery } from 'hooks/use-services/fund-account-invitations.service';
import { AuthContext } from 'providers/userProvider';
import { AuthData } from 'types/auth';
import { FUND_USERS_ROLES_TYPE_ENUM, FundUsersRoles } from 'types/FundUsersRoles';
import useIntersectionObserver from 'hooks/use-services/useIntersectionObserver';
export const RolesPermissionsPage = () => {
    const navigate = useNavigate()
    const { selectedAccount } = useContext<AuthData>(AuthContext);
    const { data: paginatedData, isFetching, isLoading, refetch,
        fetchNextPage,
        hasNextPage, isFetchingNextPage } = useGetFundRolesQuery({
            limit: 10,
            fundId: selectedAccount?.fundAccountId || 0,
        });
    const invitations = useMemo<FundUsersRoles[]>(
        () =>
            paginatedData
                ? paginatedData?.pages?.flatMap((item) => item.data)
                : [],
        [paginatedData]
    );
    const lastElementRef = useIntersectionObserver({
        isFetching,
        isLoading,
        fetchNextPage,
        hasNextPage,
    });

    return (
        <Layout>
            {() => (
                <>
                    <Box sx={{ my: 4, mx: 3 }}>
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={10}>
                                    <Breadcrumbs
                                        separator={<NavigateNextIcon fontSize="small" />}
                                        aria-label="breadcrumb"
                                    >
                                        <Link underline="hover" color="inherit" onClick={() => navigate(FundSettingsPage)}>
                                            <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Fund settings</Typography>
                                        </Link>
                                        <Typography variant='bodyCopyBold' sx={{ color: "neutral.main" }}>Roles & permissions</Typography>
                                    </Breadcrumbs>
                                    <Grid sx={{ mb: 5, mt: 2 }} container spacing={2}>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant='h3'>
                                                Roles & permissions
                                            </Typography>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={12} sm={4}>
                                            <Button onClick={() => navigate(InviteUserPagePath)} startIcon={<AddIcon />} variant='contained' color='primary' size='large'>
                                                Invite people</Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant='bodyCopyRegular'>
                                                Invite others to manage this fund, assign them roles and set their permissions. .
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Card sx={{ boxShadow: 'none', p: 4 }} >
                                        <CardContent>
                                            <Typography sx={{ color: `neutral.main`, mb: 4 }} variant="subtitle1" gutterBottom>List of people with access to the fund  </Typography>
                                            {isLoading && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={10}
                                                    textAlign={"center"}
                                                    sx={{ m: 3 }}
                                                >
                                                    {" "}
                                                    <CircularProgress color="primary" />
                                                </Grid>
                                            )}
                                            <List className='successor' sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                {invitations &&
                                                    invitations.length !== 0 &&
                                                    <>
                                                        {invitations.map((invite, index) => (
                                                            < > <ListItem
                                                                sx={{ display: '-webkit-inline-box' }}
                                                                key={index}
                                                            >
                                                                <Grid alignItems='center' container>
                                                                    <Grid item xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        {
                                                                            (invite.type === FUND_USERS_ROLES_TYPE_ENUM.EXISTED) ?
                                                                                <ListItemText sx={{ display: 'inline-grid', width: 'auto', flex: 'none' }}
                                                                                    primary={<Typography sx={{ color: `neutral.dark` }} variant='bodyCopyBold' gutterBottom>{invite.user}</Typography>}
                                                                                    secondary={<Typography sx={{ color: `neutral.lighter` }} variant='smallCopyBold' >{invite.email}</Typography>}
                                                                                /> :
                                                                                <ListItemText sx={{ width: 'auto', flex: 'none' }} primary={<Typography sx={{ color: `neutral.dark` }} variant='bodyCopyBold' gutterBottom>{invite.email}</Typography>} />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={2} textAlign={'center'}>
                                                                        <Typography sx={{ color: neutral[900] }} variant='bodyCopyRegular' >{invite.role} </Typography>

                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} textAlign={'center'}>
                                                                        <Typography sx={{ color: neutral[600] }} variant='bodyCopyRegular' > {(invite.type === FUND_USERS_ROLES_TYPE_ENUM.INVITED) ? "Invited" : "Joined"}  on {shortFormat(invite.createdAt)} </Typography>
                                                                        {
                                                                            invite.type === FUND_USERS_ROLES_TYPE_ENUM.INVITED &&
                                                                            <Typography sx={{ color: neutral[600] }} variant='smallCopyBold' paragraph>
                                                                                {
                                                                                    (numberOfDays(invite.expiredAt) < 0) ?
                                                                                        <>
                                                                                            Expired {Math.abs(numberOfDays(invite.expiredAt))} days ago
                                                                                        </> :
                                                                                        <>
                                                                                            Expires in  {numberOfDays(invite.expiredAt)} days</>
                                                                                }
                                                                            </Typography>

                                                                        }

                                                                    </Grid>
                                                                    <Grid item xs={12} sm={1} textAlign={'end'}>
                                                                        <RolesSettingsMenu user={invite} refetch={refetch} />
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItem>
                                                                {
                                                                    index !== invitations.length - 1 && <Divider key={index + 100} />
                                                                }

                                                            </>
                                                        ))}
                                                        <Box ref={lastElementRef}>
                                                            {isFetchingNextPage && (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={10}
                                                                    textAlign={"center"}
                                                                    sx={{ m: 3 }}
                                                                >
                                                                    {" "}
                                                                    <CircularProgress color="primary" />
                                                                </Grid>
                                                            )}
                                                        </Box>
                                                    </>}

                                            </List>


                                        </CardContent>
                                    </Card>
                                </Grid></Grid>



                        </React.Fragment>

                    </Box>
                </>)}
        </Layout>



    )
}
