import { Account } from "./account";
import { Category } from "./category";
import Image from "./Image";
import { User } from "./user";

export interface GrantVestmentAuthorizedViewers {
  grantVestmentAuthorizedViewersId: number;
  grantVestment: GrantVestment;
  user: User;
}
export enum GRANTVESTMENT_STATUS {
  RAISING = "RAISING",
  CLOSED = "CLOSED",
}
export interface Links {
  title: string;
  url: string;
}
export interface Tag {
  tagId: number;
  tagName: string;
}
export interface GrantVestment {
  grantVestmentId?: number;
  name: string;
  description: string;
  about: string;
  projectBy: string;
  deadline: Date;
  goalAmount: number;
  raisedAmount: number;
  allOrNothing: boolean;
  category: Category;
  imageUrl?: string;
  grantVestorCounter?: number;
  status: GRANTVESTMENT_STATUS;
  dataRoom?: Links[];
  updates?: Links[];
  tags?: Tag[];
  image?: Image;
  marketValue?: number;
  moicValue?: number;
  launchedAt?: Date;
  closedAt?: Date;
  grantAuthorizedViewers: GrantVestmentAuthorizedViewers[];
}
export enum GRANT_VESTMENT_REQUEST_STATUS_ENUM {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  PROCESSED = "PROCESSED",
}
export enum GRANT_VESTMENT_REQUEST_TYPE_ENUM {
  REQUEST = "REQUEST",
  TRANSFER_SEND = "TRANSFER_SEND",
  TRANSFER_RECEIVE = "TRANSFER_RECEIVE",
}
export interface GrantVestmentRequest {
  grantVestmentRequestId: number;
  grantVestment: GrantVestment;
  fund: Account;
  user: User;
  status: GRANT_VESTMENT_REQUEST_STATUS_ENUM;
  requestedAmount: number;
  appliedAmount: number;
  rejectionReason?: string;
  createdAt: Date;
  type: GRANT_VESTMENT_REQUEST_TYPE_ENUM;
  updatedAt: Date;
  transactionType?: string;
  executionDate: Date;
  investmentInfo?: {
    requestedAmount: number;
    interestedIn: "UNITS" | "CASH";
    appliedAmount: number;
    ticker: string;
    units: number;
  };
}
