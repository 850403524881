import React, { FormEvent } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserError } from "types/user";
import { Account } from "types/account";
interface FundDetailsProps {
  account: Account;
  handleAccountChange: (key: string, value: string) => void;
  handleInvalid: (event: FormEvent<HTMLElement>) => void;
  error: UserError;
}
export const FundDetails: React.FC<FundDetailsProps> = ({
  account,
  handleAccountChange,
  handleInvalid,
  error,
}) => {
  const [openFundNameInfo, setOpenFundNameInfo] = React.useState(false);
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        tabIndex={-1}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="detail"
        id="detail"
        className="hasText"
      >
        <Typography sx={{ color: `neutral.main` }} variant="subtitle1">
          Fund name*{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="hasText">
        <Typography
          sx={{ color: `neutral.dark` }}
          variant="bodyCopyRegular"
          paragraph
        >
          This name will be used for internal communications and also externally
          as an option for grant recognition. Click{" "}
          <Link
            onClick={(e) => {
              e.preventDefault();
              setOpenFundNameInfo(true);
            }}
            underline="always"
            color={"inherit"}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            here
          </Link>{" "}
          for more information and examples.
        </Typography>
        <Dialog
          sx={{ my: 2 }}
          fullWidth={true}
          maxWidth={"sm"}
          open={openFundNameInfo}
          onClose={() => setOpenFundNameInfo(false)}
        >
          <DialogTitle sx={{ my: 2 }}>
            {" "}
            <Typography sx={{ color: "neutral" }} variant="subtitle1">
              Naming your Fund
            </Typography>{" "}
          </DialogTitle>
          <DialogContent>
            <Typography variant="bodyCopyRegular" gutterBottom>
              The Fund name will be used for internal communications and also
              externally as an option for recognizing grants, so naming usually
              makes reference to a family name, and/or charitable values. Names
              can include words such as “Fund”, “Trust” and “Foundation” if
              desired. There are no set rules for naming of Funds, but Neta
              reserves the right to reject a name, in which case Neta will work
              with you to try to find a suitable alternative. Names can be
              changed later in Fund Settings.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setOpenFundNameInfo(false)}
              >
                Got it
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <FormControl error={error.fundAccountName} fullWidth>
              <OutlinedInput
                name="fundAccountName"
                onInvalid={(event) => handleInvalid(event)}
                value={account.fundAccountName}
                onChange={(event) =>
                  handleAccountChange("fundAccountName", event.target.value)
                }
                type="text"
                required
              />
              {error.fundAccountName && (
                <FormHelperText color={"red"}>
                  Fund name is required
                </FormHelperText>
              )}
              {error.fundAccountNameDuplicated && (
                <FormHelperText color={"red"}>Fund name is used</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
