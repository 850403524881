import { useContext, useEffect } from "react";
import Layout from "../../components/layout/layout";
import { Box, Button, Typography } from "@mui/material";

import { AuthContext } from "../../providers/userProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { GRANTVESTMENT_STATUS } from "../../types/grant-vestment";
import { AuthData } from "types/auth";
import GrantVestmentsDetailsCard from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-card";
import GrantVestmentsDetailsTags from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tags";
import GrantVestmentsDetailsTitles from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-titles";
import GrantVestmentsDetailsTabs from "../../components/grant-vestments/grant-vestment-details/grant-vestment-details-tabs";
import { warm } from "style/color";
import { moneyFormat } from "utils/money-format";
import { ReactComponent as ArrowUpIcon } from "./../../assets/Icons/arrow-up.svg";
import { useGetFundBackedGrantVestmentTotalQuery } from "hooks/use-services/fund-backed-grant-vestment.service";
import { useGetGrantVestmentQuery } from "hooks/use-services/grant-vestment.service";
import mixpanel from "mixpanel-browser";
import { ReactComponent as TransferOutgoingIcon } from "./../../assets/Icons/transfer-outgoing.svg";
import { USER_STATUS } from "types/fund-account-invitation";
import { USER_ROLE } from "types/account";

export const FundBackedGrantVestmentDetails = () => {
  const { state: grantVestmentId } = useLocation();
  const { selectedAccount } = useContext<AuthData>(AuthContext);
  const navigate = useNavigate();
  const { userData, userRole, multipleFund } =
    useContext<AuthData>(AuthContext);
  const { data: grantvestment } = useGetGrantVestmentQuery({
    grantVestmentId: parseInt(grantVestmentId.id || "0"),
  });
  const { data, isSuccess } = useGetFundBackedGrantVestmentTotalQuery({
    fundId: (selectedAccount?.fundAccountId as number) || 0,
    grantvestmentId: grantVestmentId.id,
  });

  useEffect(() => {
    if (!grantvestment?.name || !mixpanel) {
      return;
    }

    try {
      // Check if track method exists before calling it
      if (typeof mixpanel.track === "function") {
        mixpanel.track("UserOpenedGrantVestmentPage", {
          grantvestment: grantvestment.name,
          user: userData?.user
            ? `${userData.user.firstName} ${userData.user.lastName}`
            : "",
          email: userData?.user?.email || "",
        });
      }
    } catch (error) {
      console.error("Error tracking grant vestment page view:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantvestment?.name]);

  const track = (type: string, name: string, link: string) => {
    try {
      // Skip tracking if mixpanel isn't available
      if (!mixpanel || typeof mixpanel.track !== "function") {
        return;
      }

      const title =
        type === "dataRoom"
          ? "UserOpenedGrantVestmentDataRoomLink"
          : "UserOpenedGrantVestmentUpdateLink";

      mixpanel.track(title, {
        grantvestment: grantvestment?.name || "",
        title: name,
        url: link,
        user: userData?.user
          ? `${userData.user.firstName} ${userData.user.lastName}`
          : "",
        email: userData?.user?.email || "",
      });
    } catch (error) {
      // Log the error but don't let it affect the application flow
      console.error(`Error tracking ${type} link click:`, error);
    }
  };

  return (
    <Layout>
      {(handleOpen: (value: boolean) => void) => (
        <Box sx={{ m: 4 }}>
          {grantvestment && (
            <>
              {/* details-titles */}
              <GrantVestmentsDetailsTitles grantvestment={grantvestment!} />
              {/* request-details */}
              {isSuccess && (
                <Box
                  sx={{
                    backgroundColor: warm[500],
                    padding: 2,
                    borderRadius: "4px",
                    my: 3,
                    width: "100%", // Full width
                    display: "flex", // Flexbox layout
                    alignItems: "center", // Vertically center items
                    justifyContent: "space-between",
                  }}
                >
                  {grantvestment.status === GRANTVESTMENT_STATUS.RAISING && (
                    <>
                      <Typography
                        sx={{
                          color: "neutral.main",
                          display: "grid",
                          justifyContent: "center",
                          textAlign: "center",
                          flexGrow: 1,
                        }}
                        variant="subtitle3"
                      >
                        You are already backing this project with a{" "}
                        {moneyFormat(data?.totalAmount)} GrantVestment.
                      </Typography>
                    </>
                  )}
                  {grantvestment.status === GRANTVESTMENT_STATUS.CLOSED && (
                    <>
                      <Typography
                        className="title"
                        variant="smallCopy"
                        sx={{ color: "neutral.main" }}
                        gutterBottom
                      >
                        Your grantvestment value
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "neutral.main", mr: 1 }}
                        >
                          $
                          {moneyFormat(
                            data?.totalAmount! * grantvestment?.moicValue!
                          )}
                        </Typography>
                        <ArrowUpIcon />

                        <Typography
                          variant="smallCopyBold"
                          sx={{ color: "neutral.main" }}
                        >
                          MOIC {grantvestment?.moicValue}
                        </Typography>
                      </Box>
                    </>
                  )}

                  {multipleFund && (
                    <Button
                      disabled={
                        userRole !== USER_ROLE.ADMIN ||
                        userData?.user?.status === USER_STATUS.PENDING
                      }
                      variant="outlined"
                      color="primary"
                      size="large"
                      //TODO: Update to constant path
                      onClick={() =>
                        navigate(
                          `/grant-vestments/${grantVestmentId.id.toString()}/transfer`
                        )
                      }
                      startIcon={<TransferOutgoingIcon />}
                    >
                      Transfer
                    </Button>
                  )}
                </Box>
              )}
              {/* details-card */}
              <GrantVestmentsDetailsCard
                handleOpen={handleOpen}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
                buttonLabel="Back this project"
                hideProgressBar={true}
              />
              {/* details-tags */}
              <GrantVestmentsDetailsTags grantvestment={grantvestment!} />
              {/* details-tabs */}
              <GrantVestmentsDetailsTabs
                track={track}
                grantvestment={grantvestment!}
                isAuthenticated={userData?.user !== null}
                handleOpen={handleOpen}
                user={userData?.sub!}
              />
            </>
          )}
        </Box>
      )}
    </Layout>
  );
};
