import React from "react";
import { Box, Button } from "@mui/material";
import { warm } from "../../../style/color";
import packageJson from "../../../../package.json";

export const Links: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          color: warm[900],
          flexGrow: 1,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button href="/policy" target="_blank" variant="footer">
          Privacy Policy
        </Button>
        <Button variant="footer">Terms & conditions</Button>

        <Button disabled variant="footer">
          Version {packageJson.version}
        </Button>
      </Box>
    </>
  );
};
export default Links;
